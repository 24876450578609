import app from '../app';
import { recursivelyRenameProp, getOffset, createTree } from '../helpers';

app.service('SdsFilterService', function SdsFilterService(
  $rootScope,
  MAX_INT,
  InventoryService,
  SearchPageService,
  SpinnerService,
  MessagesService
) {
  const TAG_FILTER = 'tag';
  const INVENTORY_FILTER = 'inventory';
  const sps = SearchPageService;
  const structurizeTags = function (flatTags) {
    const tree = createTree(flatTags, 'tagId', 'tags');

    calculateTagAmounts(tree);

    return tree;
  };
  let activeFilterId = '';

  this.activeFilterType = TAG_FILTER;
  this.activeFilter = null;
  this.sdsFilters = [];

  this.getFilterTitle = filter => {
    if (this.activeFilterType === INVENTORY_FILTER) {
      return filter.name;
    } else if (this.activeFilterType === TAG_FILTER) {
      return filter['title_' + $rootScope.siteLanguage] || filter.title;
    } else {
      return filter.title || filter.name;
    }
  };

  this.fetchFilters = SpinnerService.wrap(async companyId => {
    const companySettings = await $rootScope.companySettingsPromise;

    const isInvEnabled = $rootScope.isModuleEnabled($rootScope.moduleNames.inv);
    let promise = Promise.resolve([]);

    if (isInvEnabled && !companySettings.firstResponderTags) {
      const inventoryService = new InventoryService(companyId, {}, ['products']);
      this.activeFilterType = INVENTORY_FILTER;

      promise = inventoryService
        .count()
        .then(function (count) {
          if (!count) return;

          return inventoryService.getInventories(MAX_INT, 0);
        })
        .then(function (inventories) {
          if (!inventories) {
            MessagesService.warning('INVENTORY.NO_INVENTORY');

            return [];
          }

          recursivelyRenameProp(inventories, 'inventories', 'children');

          return inventories;
        });
    } else {
      this.activeFilterType = TAG_FILTER;

      // get right facets
      promise = sps
        .doSearch({
          limit: sps.search.docs.length ? sps.search.limit : 0
        })
        .then(() => {
          let tags = sps.search.facets.tag;

          tags = structurizeTags(tags);
          recursivelyRenameProp(tags, 'tags', 'children');

          return tags;
        });
    }

    return promise.then(sdsFilters => {
      this.sdsFilters = sdsFilters;

      if (activeFilterId) setActiveState(this.sdsFilters, activeFilterId);

      return this.sdsFilters;
    });
  });

  this.filterSdsBy = (filterId, offset = 0) => {
    activeFilterId = filterId;

    setActiveState(this.sdsFilters, filterId);

    return sps.filterSdsBy(this.activeFilterType, filterId, offset).then(() => {
      this.activeFilter = getActiveFilter(this.sdsFilters, filterId);
    });
  };

  this.onPageChange = function () {
    const page = sps.search.page;
    const offset = getOffset(page, sps.search.limit);

    return this.filterSdsBy(activeFilterId, offset).then(function () {
      sps.search.offset = offset;
      sps.search.page = page;
    });
  };

  this.removeFilter = function () {
    sps.onReset();
    setActiveState(this.sdsFilters, null);
    this.activeFilter = null;
    activeFilterId = '';
  };

  function setActiveState(sdsFilters = [], filterId = '') {
    sdsFilters.forEach(filter => {
      filter.active = filter.id === filterId;

      setActiveState(filter.children, filterId);
    });
  }

  function getActiveFilter(sdsFilters = [], filterId = '') {
    let activeFilter = null;

    for (let filter of sdsFilters) {
      if (filter.id === filterId) {
        activeFilter = filter;
      } else if (filter.children && filter.children.length) {
        activeFilter = getActiveFilter(filter.children, filterId);
      }

      if (activeFilter != null) break;
    }

    return activeFilter;
  }
});

function calculateTagAmounts(tags) {
  let amount = 0;

  if (!tags.length) return amount;

  for (let tag of tags) {
    tag.amount += calculateTagAmounts(tag.tags);
    amount += tag.amount;
  }

  return amount;
}
