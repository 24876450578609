import { uniq } from 'lodash';
import angular from 'angular';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import app from '../app';

app.service('ExportService', [
  'ErrorService',
  '$rootScope',
  '$q',
  '$filter',
  '$translate',
  'MAX_INT',
  'Inventory',
  'Product',
  'SpinnerService',
  'ProductService',
  'SolrDocumentService',
  'SdsSearchJournal',
  'RoleFactory',
  'FireResponseReport',
  'ReportService',
  function (
    ErrorService,
    $rootScope,
    $q,
    $filter,
    $translate,
    MAX_INT,
    Inventory,
    Product,
    SpinnerService,
    ProductService,
    SolrDocumentService,
    SdsSearchJournal,
    RoleFactory,
    FireResponseReport,
    ReportService
  ) {
    const ctx = this;
    const toDay = $filter('toDay');

    /**
     * Compose CSV text out of data.
     * @param {rows} array of hashes,
     * @param {opts} hash with additional options.
     * @return string.
     */
    this.toCSV = function (rows, opts) {
      const defaults = {
        headings: Object.keys(rows[0] || {}),
        separator: ',',
        beforeHead: []
      };
      opts = angular.extend({}, defaults, opts || {});
      const wrapInQuotesIfNecessary = function (value) {
        return '"' + (value == null ? '' : value).toString().replaceAll('"', '""') + '"';
      },
      composeLine = function (row) {
        return row
          .map(function (col) {
            return wrapInQuotesIfNecessary(col);
          })
          .join(opts.separator);
      };
      let headRows = [...opts.beforeHead, opts.headings];
      if (opts.addHeadDate) {
        headRows.unshift([
          getTodayDate()
        ]);
      }
      return headRows
        .concat(
          rows.map(function (row) {
            return opts.headings.map(function (heading) {
              return row[heading];
            });
          })
        )
        .map(function (row) {
          return composeLine(row);
        })
        .join('\n');
    };

    /**
     * Add table to PDF
     * @param {rows} array of hashes
     * @param {headers} array with headers
     * @param {jsPDFDoc} object of base document
     * @param {options} object of options
     * @return string.
     */
    this.addPDFTable = async function (rows, headers, jsPDFDoc = null, options = {}) {
      const doc = jsPDFDoc || new jsPDF();

      if (rows.length === 0) {
        doc.text((await $translate('COMMON.MESSAGES.NO_ENTRIES')).toUpperCase(), 90, 40);
      } else {
        doc.autoTable(
          Object.assign(
            {
              head: [headers],
              body: rows,
              styles: {
                cellPadding: 0.5,
                fontSize: 11,
                halign: 'center',
                lineWidth: 0.1,
                lineColor: 10
              },
              columnStyles: { text: { halign: 'center' } },
              startY: 30,
              theme: 'plain',
              showHead: 'firstPage',
              rowPageBreak: 'avoid'
            },
            options
          )
        );
      }

      return doc;
    };

    /**
     * @param {content} string,
     * @param {fileName} string, optional,
     * @param {fileType} string, optional.
     */
    this.download = function (content, fileName, fileType) {
      fileName = fileName || 'export.csv';
      fileType = fileType || 'application/csv';
      var blobData = new Blob([content], { type: fileType });
      //IE11 & Edge
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blobData, fileName);
      } else {
        //In FF link must be added to DOM to be clicked
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blobData);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    this.open = function (path) {
      window.open(path, '_blank');
    };

    /**
     * @param {inventories} e.g.:
     *   [{
     *     name: '',
     *     id: '',
     *     products: [{
     *       name: '',
     *       id: '',
     *       code: '',
     *       suppliers: '',
     *       size: ''
     *     }],
     *     inventories: [...]
     *   }]
     */
    this.exportInventories = SpinnerService.wrap(function (
      inventories,
      { tags = [], andOrStrategy = 'and', includeAll = false } = {},
      format = 'csv'
    ) {
      console.log(inventories);
      const expand = function (name, products, inventoryId, namePrefix) {
          return products.map(function (prod) {
            return {
              name: name,
              inventoryId: inventoryId,
              productName: (namePrefix || '') + prod.name,
              productId: prod.id,
              code: prod.code,
              suppliers: prod.suppliers,
              size: prod.size
            };
          });
        },
        tagIds = tags.map(tag => tag.id),
        isChecked = function (inv) {
          return !!inv.checked;
        },
        flatten = function (itms, parentName) {
          parentName = parentName || [];
          return itms.reduce(function (sum, itm) {
            const name = parentName.concat(itm.name),
              checked = isChecked(itm) || includeAll;
            return sum
              .concat(checked ? expand(name, itm.products || [], itm.id) : [])
              .concat(
                checked ? expand(name, itm.inventoryContainers || [], itm.id, 'Container: ') : []
              )
              .concat(flatten(itm.inventories || [], name));
          }, []);
        },
        rows = flatten(inventories),
        uniqInventoryIds = rows.reduce(function (sum, row) {
          return ~sum.indexOf(row.inventoryId) ? sum : sum.concat(row.inventoryId);
        }, []),
        productsRequests = Inventory.requestContentItemsInInventories({
          inventoryIds: uniqInventoryIds,
          relationName: 'products',
          tagIds,
          andOrStrategy
        }).$promise,
        containersRequests = Inventory.requestContentItemsInInventories({
          inventoryIds: uniqInventoryIds,
          relationName: 'inventoryContainers',
          tagIds,
          andOrStrategy
        }).$promise;

      return $q
        .all([productsRequests, containersRequests])
        .then(async function (responses) {
          // each resp is an array,
          // so put them all together
          const data = responses[0].concat(responses[1]).reduce(function (sum, resp) {
              return sum.concat(Object.values(resp));
            }, []),
            composeHshId = function (productId, inventoryId) {
              return [productId, inventoryId].join(':');
            },
            // go once through received data,
            // and gather data for all the product-inventory pairs,
            // to ease the further lookup;
            // response expected to be of kind:
            //   [{
            //     id: '',
            //     inventoryId: '',
            //     amount: '',
            //     grade: '',
            //     manufacturer: {
            //       name: ''
            //     }
            //   }]
            perInvProdHsh = data.reduce(function (sum, itm) {
              let obj = {
                amount: itm.amount,
                grade: itm.grade,
                manufacturer: (itm.manufacturer || {}).name,
                containerType: itm.containerType || '',
                size: ProductService.showCorrectProductSize(itm),
                'Material #': uniq(
                  Object.values(itm.sdsData || {}).map(el => el['Material #'] || '')
                )
                  .filter(el => el && el.length)
                  .join(', ')
              };

              if ($rootScope.accessLocalVersion('US')) {
                obj = {
                  ...obj,
                  ...{
                    containerType: itm.containerType || '',
                    'Material #': uniq(
                      Object.values(itm.sdsData || {}).map(el => el['Material #'] || '')
                    ).join(', ')
                  }
                };
              }

              sum[composeHshId(itm.id, itm.inventoryId)] = obj;

              return sum;
            }, {}),
            // compose the results
            resData = rows.reduce(function (sum, row) {
              const invProd = perInvProdHsh[composeHshId(row.productId, row.inventoryId)];

              if (!invProd) return sum;

              let data = row.name.reduce(function (data, name, index) {
                data['Category ' + (index + 1)] = name;

                return data;
              }, {});

              let exportObj = {
                'Product name': row.productName,
                Manufacturer: invProd.manufacturer,
                'Product code': row.code,
                Suppliers: joinSupplierNames(row.suppliers),
                Grade: invProd.grade,
                Size: invProd.size,
                Quantity: invProd.amount
              };

              if ($rootScope.accessLocalVersion('US')) {
                exportObj = {
                  'Material #': invProd['Material #'],
                  ...exportObj,
                  'Container type': invProd.containerType
                };
              }

              data = angular.extend({}, data, exportObj);

              return sum.concat(data);
            }, []),
            headings = resData.reduce(function (prevKeys, data) {
              const keys = Object.keys(data);
              return keys.length > prevKeys.length ? keys : prevKeys;
            }, []);
          let content = '';
          const sortedRows = {};
          let emptyColumns = {};
          let pdfHeadings = [];
          let doc = new jsPDF();
          let page = 1;
          const strD = ': _________________________________';
          const extraPDFColumns = ['Grade', 'Container type', 'Product code'];

          switch (format) {
            case 'csv':
              content = ctx.toCSV(resData, {
                headings: headings,
                addHeadDate: true
              });
              ctx.download(content, `inventories.${format}`);
              break;
            case 'pdf':
              headings.forEach(k => (emptyColumns[k] = true));

              //find empty columns
              resData.forEach(row => {
                headings.forEach(k => {
                  if (row[k]) {
                    emptyColumns[k] = false;
                  }
                });
              });

              //remove two first, empty and extra columns from heads
              pdfHeadings = Object.keys(emptyColumns)
                .filter(el => !emptyColumns[el])
                .filter(el => !extraPDFColumns.includes(el))
                .splice(2);

              //sort rows
              resData.forEach(row => {
                let nRow = [];
                pdfHeadings.forEach(k => {
                  nRow.push(row[k]);
                  if (row[k]) {
                    emptyColumns[k] = false;
                  }
                });
                let keyStr =
                  `${row['Category 1']}` + (row['Category 2'] ? ` - ${row['Category 2']}` : '');

                if (!sortedRows[keyStr]) {
                  sortedRows[keyStr] = [];
                }
                sortedRows[keyStr].push(nRow);
              });

              pdfHeadings.push(await $translate('EXPORT_SERVICE.MAX_EST_AMT'));

              doc.setFontSize(11);

              for (const tableTitle in sortedRows) {
                if (page !== 1) {
                  doc.addPage();
                }
                page++;
                doc.setFontSize(13);
                let splitTitle = doc.splitTextToSize(tableTitle, 180);
                doc.text(15, 15, splitTitle);
                doc.setFontSize(11);

                const nextY = splitTitle.length * 5 + 15; // calc Y gap
                doc.text((await $translate('EXPORT_SERVICE.INVENTORIED_BY')) + strD, 15, nextY);
                doc.text((await $translate('EXPORT_SERVICE.PHONE')) + strD, 15, nextY + 5);
                doc.text(`${(await $translate('EXPORT_SERVICE.DATE'))}: ${getTodayDate()}`, 15, nextY + 10);

                await ctx
                  .addPDFTable(sortedRows[tableTitle], pdfHeadings, doc, {
                    startY: nextY + 20
                  })
                  .then(res => {
                    doc = res;
                  });
              }
              doc.save('inventory.pdf');
              break;
          }
        })
        .catch(ErrorService.simpleCatch);
    });

    this.exportInventoryEquipment2csv = SpinnerService.wrap(function (inventories) {
      const expand = function (name, itms, inventoryId) {
          return itms.map(function (itm) {
            return {
              name: name,
              inventoryId: inventoryId,
              id: itm.id
            };
          });
        },
        flatten = function (itms, parentName) {
          parentName = parentName || [];
          return itms.reduce(function (sum, itm) {
            const name = parentName.concat(itm.name),
              checked = !!itm.checked;
            return sum
              .concat(checked ? expand(name, itm.equipment || [], itm.id) : [])
              .concat(flatten(itm.inventories || [], name));
          }, []);
        },
        rows = flatten(inventories),
        uniqInventoryIds = rows.reduce(function (sum, row) {
          return ~sum.indexOf(row.inventoryId) ? sum : sum.concat(row.inventoryId);
        }, []),
        equipmentRequests = uniqInventoryIds.map(function (inventoryId) {
          return Inventory.getContentItemsInInventory({
            inventoryId: inventoryId,
            relationName: 'equipment'
          }).$promise;
        });

      return $q.all(equipmentRequests).then(function (resps) {
        const data = resps.reduce(function (sum, resp) {
            return sum.concat(resp);
          }, []),
          composeHshId = function (id, inventoryId) {
            return [id, inventoryId].join(':');
          },
          perInvEqHsh = data.reduce(function (sum, itm) {
            sum[composeHshId(itm.id, itm.inventoryId)] = {
              amount: itm.amount,
              name: itm.name
            };
            return sum;
          }, {}),
          resData = rows.map(function (row) {
            const invEq = perInvEqHsh[composeHshId(row.id, row.inventoryId)];
            let data = row.name.reduce(function (data, name, index) {
              data['Category ' + (index + 1)] = name;

              return data;
            }, {});

            data = angular.extend({}, data, {
              Equipment: invEq.name,
              Quantity: invEq.amount
            });

            return data;
          }),
          content = ctx.toCSV(resData);
        ctx.download(content, 'equipment.csv');
      });
    });

    this.exportProducts2csv = SpinnerService.wrap(function (products, manufacturers, companyId) {
      if (Array.isArray(products)) {
        const checkedProducts = products.filter(function (product) {
          return product.checked;
        });
        const productIds = checkedProducts.map(function (product) {
          return product.id;
        });

        return ProductService.getByIds(productIds).then(prepareProductsToExport);
      }

      if (typeof products === 'string' || products == null) {
        const params = {
          text: products,
          companyId: companyId,
          filter: { include: 'companies', where: {} }
        };

        if (manufacturers.length) {
          params.filter.where.manufacturerId = { inq: manufacturers };
        }

        return Product.search(params)
          .$promise.then(function (response) {
            return ProductService.filterCompanyUnverified(response.products, companyId);
          })
          .then(prepareProductsToExport);
      }

      return $q.reject('wrong type');

      function prepareProductsToExport(products) {
        const productsToCsv = products.map(function (product) {
          return {
            'Product name': product.name,
            'Product code': product.code,
            Suppliers: joinSupplierNames(product.suppliers),
            Manufacturer: product.manufacturer ? product.manufacturer.name : '',
            Grade: product.grade,
            Size: product.size
          };
        });
        const content = ctx.toCSV(productsToCsv);

        return ctx.download(content, 'products-export.csv');
      }
    });

    this.exportGA2csv = SpinnerService.wrap(function (rows) {
      const pageViews = rows.map(function (row) {
        return {
          Page: row[0].replace(/"/g, `'`),
          Views: row[1]
        };
      });
      const content = ctx.toCSV(pageViews);

      return $q.resolve(ctx.download(content, 'page-view-report.csv'));
    });

    this.exportSystemAudit2csv = SpinnerService.wrap(function (entries, filename) {
      const formattedResults = entries.map(function (entry) {
        return {
          Date: formatDate(entry.date),
          Type: entry.objectType,
          Name: entry.objectName,
          Action: entry.action,
          'New value': entry.newValue,
          User: entry.userName
        };
      });

      return $q.resolve(ctx.download(ctx.toCSV(formattedResults), filename));
    });

    this.exportProductsWithoutSds2csv = SpinnerService.wrap(function () {
      return ProductService.getProductsWithoutSds(0, 0).then(function (products) {
        const formattedResults = prepareProductsDataToExport(products);

        return ctx.download(ctx.toCSV(formattedResults), 'products-without-sds-report.csv');
      });
    });

    this.exportBannedSearchReport2csv = SpinnerService.wrap(function (rows) {
      const formattedRows = rows.map(function (row) {
        return {
          Timestamp: row.timestamp,
          Company: row.company && row.company.name,
          Search: row.search,
          'User Name': row.user && row.user.username,
          'User Role': row.user && row.user.role
        };
      });

      return $q.resolve(
        ctx.download(ctx.toCSV(formattedRows), 'banned-chemicals-searches-report.csv')
      );
    });

    this.exportAddedRemovedSds = SpinnerService.wrap(function (rows, criteria) {
      const composeExportFilename = function (criteria) {
        return ['added (removed) SDSs', toDay(new Date()), 'report'].join('-') + '.csv';
      };
      const getSdsName = $filter('getSdsNameAggregate');
      const getTags = (tagsChange, type) => {
        return tagsChange.reduce((all, el) => {
          if (el[type]) {
            let tagsNames = Object.values(el[type]).join(', ');
            all.push(`${toDay(el.date, 'yyyy-MM-dd H:m')} - ${tagsNames}`);
          }
          return all;
        }, []).join("\n");
      }

      const formattedRows = rows.reduce(function (acc, el) {
        for (let i in el.SDS) {
          const addedRemoved = el.SDS[i].companyInclude
            ? 'Added'
            : el.SDS[i].companyInclude === false ? 'Removed' : null;
          let val = {
            Company: el._id.companyName,
            SDS: getSdsName(el.SDS[i]),
            Date: addedRemoved ? toDay(el.SDS[i].maxDate) : '',
            'Added/Removed': addedRemoved,
            'Added Tags': getTags(el.SDS[i].tagsChange, 'added'),
            'Removed Tags': getTags(el.SDS[i].tagsChange, 'removed')
          };

          if (criteria.showSDSTags) {
            val['Company Tags'] = (el.SDS[i].tagsPath || []).join("\n");
          }

          if (criteria.showSDSInventory) {
            val['Inventory'] = (el.SDS[i].inventoryList || []).join("\n");
          }

          acc.push(val);
        }
        return acc;
      }, []);

      return $q.resolve(ctx.download(ctx.toCSV(formattedRows), composeExportFilename(criteria)));
    });

    this.exportCheckedDateReport2csv = SpinnerService.wrap(function (rows, criteria) {
      const composeExportFilename = function (criteria) {
        return ['checked', criteria.span || 'on', toDay(criteria.day), 'report'].join('-') + '.csv';
      };
      const yesNo = $filter('yesNo');
      const arrToString = function (arr) {
        return Array.isArray(arr) ? arr.join(', ') : arr.toString;
      };
      const getSdsName = $filter('getSdsName');
      // const getHazards = function (names) {
      //   return (names || []).map(function (name) {
      //     return HazardService.getTitle(name);
      //   }).join(', ');
      // };
      const formattedRows = rows.map(function (doc) {
        let r = {
          Name: getSdsName(doc),
          Language: arrToString(doc.language),
          Manufacturer: doc.manufacturer.name,
          'MSDS Format': yesNo(doc.msds_format),
          'Issue Date': decodeURIComponent(doc.issueDate),
          'Last Checked Date': toDay(doc.dateModified || doc.dateCreated)
        };

        if (criteria.showSDSTags) {
          r['Company Tags'] = (doc.tagsPath || []).join("\n");
        }

        if (criteria.showSDSInventory) {
          r['Company Inventory'] = (doc.inventoryList || []).join("\n");
        }
        return r;
      });

      return $q.resolve(ctx.download(ctx.toCSV(formattedRows), composeExportFilename(criteria)));
    });

    this.exportArchivedSdsReport2csv = SpinnerService.wrap(function (rows) {
      const arrToString = function (arr) {
        return Array.isArray(arr) ? arr.join(', ') : arr.toString;
      };
      const getSdsName = $filter('getSdsName');
      const formattedRows = rows.map(function (doc) {
        let r = {
          Name: getSdsName(doc),
          Company: doc.archivedTagsCompany,
          Language: arrToString(doc.language)
        };
        return r;
      });

      return $q.resolve(ctx.download(ctx.toCSV(formattedRows), 'ArchivedSdsReport.csv'));
    });

    this.exportProductsDontRequireSdsReport2csv = SpinnerService.wrap(function () {
      return ProductService.getProductsDontRequireSds(0, 0).then(function (response) {
        const formattedResults = prepareProductsDataToExport(response.products);

        return ctx.download(ctx.toCSV(formattedResults), 'non-osha-regulated-products.csv');
      });
    });

    this.exportBannedProductsReport2csv = SpinnerService.wrap(function (
      companyId,
      inventoried = null
    ) {
      return ProductService.getBannedProducts(0, 0, companyId, inventoried).then(async function (
        response
      ) {
        if (inventoried) {
          response.products = await ProductService.assignInventoryPath(
            response.products,
            companyId,
            arr => {
              return arr.join('\n');
            }
          );
        }
        const formattedResults = prepareProductsDataToExport(response.products);

        return ctx.download(ctx.toCSV(formattedResults), 'banned-products-report.csv');
      });
    });

    this.exportSdsSearchJournal2csv = SpinnerService.wrap(async function (companyId) {
      await ReportService.getDictionary();

      return SdsSearchJournal.find({
        filter: companyId ? { where: { 'company.id': companyId } } : {}
      }).$promise.then(function (records) {
        const formattedRows = records.map(function (record) {
          ReportService.fillRecordWithFilterValues(record);

          let searchStr = record.search;
          if (record.filterValues) {
            Object.keys(record.filterValues).forEach(key => {
              searchStr += `\n${$translate.instant(
                `REPORTS.SDS_SEARCH_JOURNAL.SEARCH_FILTER.${key}`
              )}: ${record.filterValues[key].join(', ')}`;
            });
          }

          const data = {
            Timestamp: record.timestamp,
            Company: record.company && record.company.name,
            Search: searchStr,
            'User Name': record.user && record.user.username,
            'User Role': record.user && RoleFactory.getVisibleRoleName(record.user.role)
          };

          if (companyId) delete data.Company;

          return data;
        });

        ctx.download(ctx.toCSV(formattedRows), 'sds-search-journal.csv');
      });
    });

    this.exportWCL2Excel = SpinnerService.wrap(async function (
      companyId,
      lang,
      inventoryIds,
      offset = 0,
      limit = MAX_INT
    ) {
      const result = await Inventory.getWCLexcel({ companyId, lang, inventoryIds, offset, limit })
        .$promise;
      const bytes = new Uint8Array(result.data);

      ctx.download(
        bytes,
        'WCL.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    });

    this.exportWCL2Pdf = SpinnerService.wrap(async function (companyId, lang, inventoryIds) {
      const result = await Inventory.getWCLpdf({ companyId, lang, inventoryIds }).$promise;
      const bytes = new Uint8Array(result.data);

      ctx.download(bytes, 'WCL.pdf', 'application/pdf');
    });

    this.exportDiscontinuedReport2csv = SpinnerService.wrap(function (companyId, dateFrom, dateTo) {
      return SolrDocumentService.getDiscontinuedSdss(companyId, {
        dateFrom,
        dateTo,
        availableLanguages: $rootScope.languages
      }).then(function (response) {
        const formattedRows = response.docs.map(doc => ({
          'Chemical Friendly Name': doc.chemicalFriendlyName,
          'Discontinued Date': toDay(doc.dateDiscontinued)
        }));

        return ctx.download(ctx.toCSV(formattedRows), 'discontinued-sdss.csv');
      });
    });

    this.exportFireResponseReport = SpinnerService.wrap(async function (
      companyId,
      type,
      inventoryIds
    ) {
      const result = await FireResponseReport.export({ companyId, type, inventoryIds }).$promise;
      const bytes = new Uint8Array(result.data);
      let headerExt = 'application/pdf';
      let ext = type;
      if (type === 'xls') {
        ext = 'xlsx';
        headerExt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
      ctx.download(bytes, 'FireResponseReport.' + ext, headerExt);
    });

    this.cbBinaryToFile = SpinnerService.wrap(async function (getBinaryFunc, type, options = {}) {
      const result = await getBinaryFunc();
      const bytes = new Uint8Array(result);
      let headerExt = 'application/pdf';
      let ext = type;
      if (type === 'xls') {
        ext = 'xlsx';
        headerExt = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
      ctx.download(bytes, options.fileName || 'Report.' + ext, headerExt);
    });

    function prepareProductsDataToExport(products) {
      return products.map(function (product) {
        return {
          'Product name': product.name,
          'Product code': product.code,
          Suppliers: joinSupplierNames(product.suppliers),
          Manufacturer: product.manufacturer && product.manufacturer.name,
          Grade: product.grade,
          Size: ProductService.showCorrectProductSize(product),
          'Inventory Path': product.inventoryTree
        };
      });
    }

    function formatDate(date) {
      return $filter('date')(date, 'yyyy-MM-dd HH:mm:ss');
    }
  }
]);

function joinSupplierNames(suppliers = []) {
  return suppliers.map(supplier => supplier.name).join('; ');
}

function getTodayDate() {
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const todayDate  = new Date();

  return todayDate.toLocaleDateString("en-US", dateOptions)
}
