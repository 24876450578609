import app from '../../app';

app.controller('historyRequestModalCtrl', [
  '$uibModalInstance',
  '$window',
  'title',
  'history',
  'SdsRequestService',
  historyRequestModalCtrl
]);

function historyRequestModalCtrl($uibModalInstance, $window, title, history, SdsRequestService) {
  const modal = this;

  modal.title = title;
  modal.history = history;
  modal.requestService = SdsRequestService;
  modal.cancel = $uibModalInstance.dismiss;

  // TODO: use something like UserService for this
  modal.getUsername = function (user) {
    user = user || {};

    return user.username === '_admin' ? 'UsSDS' : user.username;
  };

  modal.print = function () {
    $window.print();
  };
}
