import angular from 'angular';
import app from '../../app';

app.controller('labelBatchMakerCtrl', [
  '$state',
  '$q',
  '$scope',
  '$rootScope',
  '$translate',
  '$timeout',
  'LabelTemplatesService',
  'LabelBatchService',
  'SolrDocument',
  'StorageCodes',
  'DisposalCodes',
  'Manufacturer',
  'SpinnerService',
  'CompanySetting',
  'CompanyService',
  labelBatchMakerCtrl
]);

function labelBatchMakerCtrl(
  $state,
  $q,
  $scope,
  $rootScope,
  $translate,
  $timeout,
  LabelTemplatesService,
  LabelBatchService,
  SolrDocument,
  StorageCodes,
  DisposalCodes,
  Manufacturer,
  SpinnerService,
  CompanySetting,
  CompanyService
) {
  const state = $state.params.state ? JSON.parse($state.params.state) : {};
  const vm = this;
  const docsIds = $state.params.ids;
  const labelsQuantity = state.labelsQuantity || {};
  const companyId =
    ($rootScope.currentUser && $rootScope.currentUser.companyId) || $rootScope.companyId;

  if (
    !$rootScope.companySettings.addNJCASToLabel &&
    (state.addNJCASToLabel || state.showNJLabels)
  ) {
    vm.addNJCASToLabel = false;
    vm.showNJLabels = false;
  } else {
    vm.addNJCASToLabel =
      state.addNJCASToLabel == null
        ? $rootScope.companySettings.addNJCASToLabel
        : state.addNJCASToLabel;
    vm.showNJLabels = state.showNJLabels;
  }
  vm.printQrCodeOnly = state.printQrCodeOnly;
  vm.getAttrLangName = CompanyService.getAttrLangName;
  vm.customPrintableFields = state.customPrintableFields;
  vm.customFields = state.customFields;
  vm.hasCustomFields = !!vm.customFields;
  if (!vm.customFields || !vm.customPrintableFields) {
    CompanySetting.findById(
      { id: companyId, filter: { include: ['storageCodes', 'disposalCodes'] } },
      function (data) {
        vm.customPrintableFields = [];
        vm.customFields = [];

        for (let key in data.attrs) {
          if ((data.printableAttrs || []).includes(key)) {
            vm.customPrintableFields.push({ header: key, showCustomFields: true });
          } else {
            vm.customFields.push({ header: key, showCustomFields: false });
          }
        }

        if (vm.customFields.length > 0) vm.hasCustomFields = true;
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
      }
    );
  }
  const defaultLabelStyle = LabelTemplatesService.defaultLabelStyle;
  const emphases = [
    { match: /:/, replace: /.*?:/ },
    { match: /#/, replace: /.*?#/ },
    { match: /!/, replace: /.*?!/ },
    { match: /\b[A-Z]{2,}\b/g, replace: /\b[A-Z]{2,}\b/g }
  ];
  let labels = [];

  startWriteToSettings([
    'selectedTemplate',
    'borderWidth',
    'borderColor',
    'labelTemplate',
    'qrcode',
    'qrcodeSize',
    'borderStyle',
    'showManu',
    'showStorageCode',
    'showDisposalCode',
    'labelStyle',
    'additionalInformation',
    'customFields',
    'customPrintableFields',
    'addNJCASToLabel',
    'showNJLabels',
    'printQrCodeOnly',
    'showPPE'
  ]);

  vm.templateTypes = [
    { type: 'MAIN', label: 'LABELMAKER.CONTENT.LABEL_TEMPLATE' },
    { type: 'BLANK', label: 'LABELMAKER.CONTENT.BLANK_WORKSPACE_LABELS' }
  ];
  vm.selectedTemplate = state.selectedTemplate
    ? selectTemplateByType(state.selectedTemplate.type)
    : vm.templateTypes[0];
  vm.hazardTypes1 = ['healthHazard', 'flame', 'exclamationMark', 'corrosion', 'gasCylinder'];
  vm.hazardTypes2 = [
    'explodingBomb',
    'skullAndCrossbones',
    'flameOverCircle',
    'environment',
    'biohazard'
  ];
  vm.fontSize = state.fontSize || 12;
  vm.borderWidth = state.borderWidth || 10;
  for (let key in state) {
    if (state.hasOwnProperty(key)) {
      if (/showCustomFields/i.test(key)) vm[key] = state[key] || false;
    }
  }
  vm.borderColor = state.borderColor || '#000000';
  vm.riskPhrase =
    'Risk Phrase : copy only the “1st sentence” of CAUTION/WARNING/DANGER statements from the “Hazards Identification” section';
  vm.handlingPhrase =
    'Precautionary Measures, pulled from “Handling and Storage” copy only the “Handling” part of this section';
  vm.riskPhraseHTML = vm.riskPhrase;
  vm.handlingPhraseHTML = vm.handlingPhrase;
  vm.canPrint = false;
  vm.additionalInformation = state.additionalInformation || '';
  vm.showManu = state.showManu == null ? false : state.showManu;
  vm.showPPE = state.showPPE == null ? false : state.showPPE;

  vm.codes = state.codes || {};
  vm.showStorageCode = state.showStorageCode;
  vm.showDisposalCode = state.showDisposalCode;

  vm.labelStyle = angular.extend({}, defaultLabelStyle, state.labelStyle);

  vm.labelTemplates = LabelTemplatesService.known.map(
    LabelTemplatesService.calculateMeasuranceInPixel
  );
  vm.labelTemplate = state.labelTemplate || vm.labelTemplates[0];

  CompanyService.getCurrentCompanyPromise().$promise.then(() => {
    //init qr code settings
    if (state.qrcode == null) {
      vm.qrcode = $rootScope.companySettings.qrCodeOnLabelByDefault;
      if (vm.labelTemplate.qrcodeDisabled) {
        vm.qrcode = false;
      }
    } else {
      vm.qrcode = state.qrcode;
    }
  });

  vm.changeAddNJCasToLabels = function () {
    if (!vm.addNJCASToLabel && vm.showNJLabels) {
      vm.showNJLabels = false;
    }
    vm.reloadLabels();
  };

  vm.reloadLabels = function () {
    LabelTemplatesService.flushPreviousLabels();
    getDocumentsById(docsIds.split(','));
  }

  vm.changePrintQrCodeOnly = function () {
    if (vm.printQrCodeOnly) {
      vm.showManu = true;
    }
    if (!!vm.printQrCodeOnly && !!vm.showNJLabels) {
      vm.showNJLabels = false;
      vm.reloadLabels();
    }
    vm.adjustSizesDelayed(500);
  };

  vm.getHeight = function (template) {
    if (template.pageSize === '3in 6in') return 12;
    if (template.pageSize === '4in 6in' && template.printType === 'continuous') return 20;
    if (template.pageSize === '2.4in 6in' && template.printType === 'continuous') return 10;
    if (template.numRows <= 1) {
      switch (template.pageSize) {
        case '4in 6in':
          return 25;
        default:
          return 28.8;
      }
    }
    if (template.numRows == 2) return 17.3;
    if (template.numRows == 5) return 9;

    return 15;
  };

  vm.upDownControl = function (target, amount) {
    vm[target] = parseInt(vm[target]) + amount;

    if (vm.qrcodeSize <= 50) {
      vm.qrcodeSize = 50;
    }
  };

  //trims one sentence or line from the target string on the viewmodel
  vm.trimSection = function (target) {
    var lines = vm[target].split(/\n/);
    var lastLine = lines[lines.length - 1];
    lastLine = lastLine.trim();

    if (lastLine == '') {
      //empty line
      lines.pop();
    } else if (lastLine[lastLine.length - 1] != '.' && lastLine.indexOf('.') >= 0) {
      //sentence fragment
      lastLine = lastLine.slice(0, lastLine.lastIndexOf('.') + 1);
      lines[lines.length - 1] = lastLine;
    } else {
      var sentences = lastLine.match(/.*?\./g);

      if (sentences) {
        sentences.pop();
        lines[lines.length - 1] = sentences.join('');
      } else {
        lines.pop();
      }
    }

    vm[target] = lines.join('\n');
    vm.updateSectionHTML(target);
  };

  vm.joinSectionLines = function (target) {
    if (!vm[target]) return;

    vm[target] = vm[target].replace(/\n/g, ' ');
    vm[target] = vm[target].replace(/\s+/g, ' ');
    vm.updateSectionHTML(target);
  };

  vm.updateSectionHTML = function (target) {
    var targetHTML = target + 'HTML';
    vm[targetHTML] = LabelTemplatesService.addEmphasis(vm[target], emphases);
    vm.adjustSizesDelayed();
  };

  vm.prepareLabelsForPrint = function (delay, mode) {
    if (mode === 'ChangeTemplate') {
      vm.addNJCASToLabel = $rootScope.companySettings.addNJCASToLabel;
      vm.showNJLabels = false;
      vm.reloadLabels();
    }
    LabelTemplatesService.flushPreviousLabels();
    placeLabels(labels);
    if (vm.labelTemplate.qrcodeDisabled) {
      vm.qrcode = false;
      vm.printQrCodeOnly = false;
    } else {
      vm.qrcode =
        state.qrcode != null ? state.qrcode : $rootScope.companySettings.qrCodeOnLabelByDefault;
    }

    vm.adjustSizesDelayed(delay);
  };

  vm.onTemplateChange = function () {
    if (vm.selectedTemplate.type === 'MAIN') {
      LabelTemplatesService.flushPreviousLabels();
      vm.adjustSizesDelayed(500);
    } else {
      vm.fontSize = state.fontSize || 12;
    }
  };

  vm.adjustSizes = function () {
    LabelTemplatesService.adjustLabelsSize(vm);
  };

  vm.adjustSizesDelayed = function (delay) {
    $timeout(vm.adjustSizes, delay || 10);
  };

  if (state.additionalInformation) {
    vm.updateSectionHTML('additionalInformation');
  }

  const getDocumentsById = SpinnerService.wrap(function (ids) {
    $translate('LABELMAKER.CONTENT.SAFETY_DATA_SHEET').then(function (translation) {
      vm.sdsLocationHTML = '<strong>' + translation + '</strong>';
    });

    return $q
      .all(
        ids.map(function (id) {
          const options = {
            idList: 'id:' + id,
            fieldList:
              'id,chemicalFriendlyName,handling_storage,safe_handling,hazards_identification,hazardType,storageCodes,disposalCodes,manufacturer,attr_*,new_jersey_right,ppe_*'
          };

          return SolrDocument.findByIdList(options).$promise;
        })
      )
      .then(function (results) {
        const docs = results.map(function (result) {
          return result.response.docs[0];
        });
        return $q
          .all(
            docs.map(function (doc) {
              return $q.all({
                id: doc.id,
                chemicalFriendlyName: doc.chemicalFriendlyName,
                attr: doc['attr_' + companyId] || [],
                handling_storage: doc.handling_storage,
                labelsQuantity: labelsQuantity[doc.id],
                hazardType: doc.hazardType,
                hazards_identification: doc.hazards_identification,
                safe_handling: doc.safe_handling,
                new_jersey_right: vm.addNJCASToLabel
                  ? decodeURIComponent(doc.new_jersey_right || '')
                  : null,
                new_jersey_right_info: decodeURIComponent(doc.new_jersey_right || ''),
                showNJLabels: vm.showNJLabels,
                [`ppe_${$rootScope.companyId}`]: doc[`ppe_${$rootScope.companyId}`] || [],
                storage:
                  doc.storageCodes &&
                  StorageCodes.findOne({
                    filter: {
                      where: {
                        id: { inq: doc.storageCodes },
                        isEnabled: true,
                        companySettingId: companyId
                      }
                    },
                    fields: ['id', 'title', 'color', 'description'],
                    limit: 1
                  }).$promise.catch(angular.noop),
                disposal:
                  doc.disposalCodes &&
                  DisposalCodes.findOne({
                    filter: {
                      where: {
                        id: { inq: doc.disposalCodes },
                        isEnabled: true,
                        companySettingId: companyId
                      }
                    },
                    fields: ['id', 'title', 'color', 'description'],
                    limit: 1
                  }).$promise.catch(angular.noop),
                manu:
                  doc.manufacturer &&
                  Manufacturer.findOne({
                    filter: { where: { id: doc.manufacturer } },
                    fields: ['id', 'name']
                  })
                    .$promise.then(function (resp) {
                      return resp.name;
                    })
                    .catch(angular.noop)
              });
            })
          )
          .then(function (resp) {
            labels = makeLabelsFromDocs(resp);
            vm.printItems = labels;
            vm.haveNJRTKInfo = vm.printItems.some(item => !!item.new_jersey_right_info);

            prepareLabels(labels);
            placeLabels(labels);
            vm.prepareLabelsForPrint(1000);

            function makeLabelsFromDocs(docs) {
              return docs.reduce(function (labels, doc) {
                let labelsPerDoc = doc.labelsQuantity;

                delete doc.labelsQuantity;

                while (labelsPerDoc) {
                  labels.push(doc);
                  labelsPerDoc--;
                }
                if ((doc.new_jersey_right || '').trim().length > 0) {
                    if (vm.showNJLabels || doc.showNJLabels) {
                      let labelDoc = Object.assign({ njLabel: true }, doc);
                      labels.push(labelDoc);
                    } else {
                      doc.addNJContest = true;
                    }
                }
                return labels;
              }, []);
            }

            function prepareLabels(labels) {
              labels.forEach(function (label) {
                label.name = decodeURIComponent(label.chemicalFriendlyName);

                let safeHandlingPhrase = '';

                if (label.safe_handling && label.safe_handling !== 'undefined') {
                  safeHandlingPhrase = decodeURIComponent(label.safe_handling);
                } else {
                  safeHandlingPhrase = decodeURIComponent(label.handling_storage);
                }

                label.safeHandlingPhraseHTML = LabelTemplatesService.addEmphasis(
                  safeHandlingPhrase,
                  emphases
                );
              });
            }
          });
      })
      .then(function () {
        return $timeout(vm.adjustSizes, 500);
      });
  });

  LabelTemplatesService.flushPreviousLabels();
  LabelTemplatesService.getLabelSettings(companyId).then(function (settings) {
    const codesFilter = {
      where: {
        companySettingId: companyId,
        isEnabled: true
      }
    };

    vm.borderStyles = settings.borderStyles;
    vm.borderStyle = state.borderStyle || vm.borderStyles[0];

    vm.labelTemplates = LabelTemplatesService.pick(settings.labelTemplates).map(
      LabelTemplatesService.calculateMeasuranceInPixel
    );
    vm.labelTemplate = state.labelTemplate || vm.labelTemplates[0];

    vm.labelStyle = angular.extend({}, defaultLabelStyle, settings.labelStyle, state.labelStyle);

    DisposalCodes.count(codesFilter).$promise.then(function (resp) {
      if (resp.count) {
        vm.hasDisposalCodes = true;
      } else {
        vm.hasDisposalCodes = false;
      }
    });
    StorageCodes.count(codesFilter).$promise.then(function (resp) {
      if (resp.count) {
        vm.hasStorageCodes = true;
      } else {
        vm.hasStorageCodes = false;
      }
    });

    getDocumentsById(docsIds.split(','));
  });

  function placeLabels(labels) {
    const cellsPerRow = vm.labelTemplate.numLabelsPerRow;
    let row = [];

    vm.labelRows = [];

    labels.forEach(function (label, index) {
      if (index !== 0 && !(index % cellsPerRow)) {
        vm.labelRows.push(row);
        row = [];
      }

      row.push(label);

      if (index === labels.length - 1) vm.labelRows.push(row);
    });
  }

  function startWriteToSettings(settingsToWrite) {
    settingsToWrite.forEach(function (settingName) {
      $scope.$watch('ctrl["' + settingName + '"]', function (newValue) {
        LabelBatchService.printSettings(settingName, newValue);
      });
    });
  }

  function selectTemplateByType(type) {
    return vm.templateTypes.filter(function (template) {
      return template.type === type;
    })[0];
  }
}
