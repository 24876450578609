import app from '../../app';

app.directive('azureSpecialAccess', [
  function () {
    return {
      restrict: 'E',
      templateUrl: require('./azureSpecialAccess.html'),
      scope: {
        commonSettings: '=',
        companyId: '='
      },
      controllerAs: 'vm',
      controller: function (
        $rootScope,
        $scope,
        CompanySetting,
        SpinnerService,
        MessagesService,
        ErrorService
      ) {
        if (!$rootScope.checkIfUserIs('admin')) return;

        const vm = this;
        const simpleCatch = ErrorService.simpleCatch;

        const specialAccesses = {
          ca: [],
          us: [{ translate: 'SETTINGS.MODULES.REPORTFIRERESPONSE', value: 'ReportFireResponse' }]
        };
        vm.specialAccesses = specialAccesses[$rootScope.localVersion.toLowerCase()];
        vm.azureAccessByProps = ['groups', 'mail', 'userPrincipalName', 'jobTitle'];

        vm.updateAzureSpecialAccess = SpinnerService.wrap(function (specialAccess) {
          const azureSpecialAccessSettingsName = `azureSpecialAccessSettings.${specialAccess}`;

          return CompanySetting.prototype$updateAttributes(
            { id: $scope.companyId },
            { [azureSpecialAccessSettingsName]: vm.azureSpecialAccess[specialAccess] }
          )
            .$promise.then(() => MessagesService.success('COMMON.MESSAGES.SAVE_COMPLETE'))
            .catch(simpleCatch);
        });

        init();

        function init() {
          $scope.$watch('commonSettings', function (newValue, oldValue) {
            vm.azureSpecialAccess =
              $scope.commonSettings.azureSpecialAccessSettings || initSpecialAccesses();
          });
        }

        function initSpecialAccesses() {
          return vm.specialAccesses.reduce((sum, specialAccess) => {
            sum[specialAccess.value] = {
              azureAccessBy: vm.azureAccessByProps[0],
              azureStaffMark: ''
            };

            return sum;
          }, {});
        }
      }
    };
  }
]);
