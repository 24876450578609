import app from '../app';

app.service('AuthService', [
  '$rootScope',
  'SdsUser',
  'CookieManager',
  'LoopBackAuth',
  'SpinnerService',
  'ErrorService',
  function ($rootScope, SdsUser, CookieManager, LoopBackAuth, SpinnerService, ErrorService) {
    const simpleCatch = ErrorService.simpleCatch;
    let initialLocation = null;

    this.setInitialLocation = function (location) {
      if (location instanceof window.Location) {
        if (
          location.pathname !== '/' &&
          location.pathname !== '/login' &&
          location.pathname !== '/logout'
        ) {
          initialLocation = { ...location };
        }
      } else {
        console.error('location should be an instance of window.Location');
      }
    };
    this.clearInitialLocation = function () {
      initialLocation = null;
    };

    this.getUserRole = function () {
      return $rootScope.currentUser && $rootScope.currentUser.role
        ? $rootScope.currentUser.role
        : $rootScope.IPallowed
        ? 'teamMember'
        : '';
    };

    this.login = SpinnerService.wrap(function (rememberMe, credentials) {
      return SdsUser.login({ rememberMe }, credentials)
        .$promise.catch(() =>
          SdsUser.systemLogin({ rememberMe: rememberMe }, credentials)
            .$promise.then(accessToken => {
              LoopBackAuth.setUser(accessToken.id, accessToken.userId, accessToken.user);
              LoopBackAuth.rememberMe = rememberMe !== false;
              LoopBackAuth.save();
              return accessToken;
            })
            .then(accessToken => {
              return SdsUser.findById({
                id: accessToken.userId,
                filter: {
                  include: { relation: 'allowedCompanies', scope: { fields: ['id', 'name'] } }
                }
              }).$promise.then(user => {
                accessToken.allowedCompanies = user.allowedCompanies || [];

                return accessToken;
              });
            })
            .catch(simpleCatch)
        )
        .then(res => {
          if (initialLocation) document.location.href = initialLocation.href;

          return res;
        });
    });

    this.logout = SpinnerService.wrap(function () {
      $rootScope.currentUser = null;
      CookieManager.removeCookies();
      sessionStorage.setItem('currentUser', '');

      return SdsUser.logout()
        .$promise.then(() => {
          $rootScope.sitename = $rootScope.getSchoolName(false);
        })
        .catch(simpleCatch);
    });

    this.setCurrentCompanyId = function () {
      if (!$rootScope.companyId) {
        $rootScope.companyId =
          $rootScope.currentUser && $rootScope.currentUser.companyId
            ? $rootScope.currentUser.companyId
            : $rootScope.companyId;
      }
      return $rootScope.companyId;
    };
  }
]);
